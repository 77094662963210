import React, { useState, useEffect } from 'react';
import RoadShowMap from '../../components/roadshow/roadshow';
// import Ecosystem from '../../data/ecosystem.json';
// import {map} from 'lodash';
import { Grid } from '@material-ui/core';
import Layout from '../../components/layout';
import SectionContainerLayoutWithDate from '../../components/section-container-layout-with-date/section-container-layout-with-date';
import axios from 'axios';

export default function RoadShowFuturePage(props) {
  // const [mapLocations] = useState(_.filter(Ecosystem, { code: 'RDSH' })); // the default locations on map
  // const [sections, setSections] = useState([]);
  const [roadShowPartners, setRoadShowPartners] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_CMS_API_URL}/partner`)
      .then((_responses) => {
        if (_responses.status === 200) {
          setRoadShowPartners(_responses.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // useEffect(() => {
  //   const category = Ecosystem[0].data.map(({ name }) => {
  //     return { name };
  //   });
  //   const theSections = map(category, 'name');
  //   setSections(theSections);
  //   // eslint-disable-next-line
  // }, []);

  return (
    <Layout>
      <SectionContainerLayoutWithDate title='GLOBAL NETWORK'>
        <Grid container>
          <Grid item xs={12}>
            <RoadShowMap page={true} roadShow={true} appear={true} mapLocations={roadShowPartners} />
          </Grid>
        </Grid>
      </SectionContainerLayoutWithDate>
    </Layout>
  );
}
